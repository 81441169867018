import { Button, Dialog, DialogContent, IconButton, Paper, Table, TableBody } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss'
import scss from './PeptideDFBPIDResult.module.scss';
// import scssCommon from 'assets/scss/common.scss';
import { breadData } from 'components/bread/BreadBar';
import { cardData } from 'components/bread/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText, TagTextWithScrollBar } from 'components/tag/Text';
import { MuiTableBody, MuiTableCell, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import cookie from 'utils/cookie';
import { outside } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage2 from 'layouts/LayoutPage2';
import links from 'utils/links';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getLinkFromArray, getScssNameCell } from 'components/peptide/sppID';
import { handleClickBitness, handleClickEHPtool } from './PeptideDFBPIDResult';
import { handleScroll } from 'components/peptide/sppLayout';

//---------------------------------------------------------------------------
// Go in: 'Peptide' page -> '19 Multifunctional peptides' item -> any 'SPP ID' -> this page
// path: paths.spp.peptide.id_multi_result(),
const jsons = spp.peptide.search.id_multi
const jsonsPrecursor = spp.peptide.search.id_precursor_result
const texts = {
  tabs: [
    'Multifunctional activity',
    'Physicochemical Properties',
    'Peptide Source & Food-borne Protein(s) Search',
    'Taste Properties',
    '3D Structure',
    
    'Peptide Stability',
    'Database Cross-references',
  ],
}

const tabs = {
  _00card: {
    head: jsons.output_text.slice(0, 3 + 1),
    json: jsons.output.slice(0, 3 + 1),
  },

  _0multifunctional: {
    title: texts.tabs[0],
    head: [
      ...jsons.output_text.slice(4, 5 + 1),
    ],
    json: [
      ...jsons.output.slice(4, 5 + 1),
    ],
  },

  _1physical: {
    title: texts.tabs[1],
    head: [
      ...jsons.output_text.slice(6, 13 + 1),
      'Peptide Calculator',
    ],
    json: [
      ...jsons.output.slice(6, 13 + 1),
      'To calculate the physicochemical properties of bioactive peptide.',
    ],
    button_peptide: 'Peptide Calculator',
    unit_c: function (value) {
      return `<span>${value}<sup>C</sup></span>` //c
    },
  },

  _2peptide: {
    title: texts.tabs[2],
    head: [
      ...jsons.output_text.slice(14, 14 + 1),
    ],
    json: [
      ...jsons.output.slice(14, 14 + 1),
    ],
    precursor_first: function (number) {
      return (`Source ${number + 1}: `)
    },
    no_precursor: 'No matching precursor protein found',
    button_view: 'View HotSpot',
    button_inductive: 'Inductive Analysis',
  },

  _3taste: {
    title: texts.tabs[3],
    head: [
      ...jsons.output_text.slice(15, 15 + 1),
    ],
    json: [
      ...jsons.output.slice(15, 15 + 1),
    ],
    bitness: 'prediction',
  },
  
  _31structure: {
    title: texts.tabs[4],
    head: [
      ...jsons.output_text.slice(16, 17 + 1),
    ],
    json: [
      ...jsons.output.slice(16, 17 + 1),
    ],
  },

  _4stability: {
    title: texts.tabs[5],
    head: [
      // "Databases",
      // "Predict tools",
      "Databases: Predict tools",
    ],
    json: [
      'SPP',
      'ExPASy',
    ],
    body: [
      'Enzymatic Hydrolysis Prediction Tool (EHP-Tools)',
      'PeptideCutter',
    ],
    links: [
      { 'Enzymatic Hydrolysis Prediction Tool (EHP-Tools)': '' },
      { 'PeptideCutter': "https://web.expasy.org/peptide_cutter/" },
    ]
  },

  _5database: {
    title: texts.tabs[6],
    head: [
      ...jsons.cross_references_text.slice(0, 4 + 1),
    ],
    json: [
      ...jsons.output.slice(18, 18 + 1),
    ],
    text_d: function (number) {
      return (`[D${number}] `)
    },
  },
}

const MuiButton = (props) => {
  return (
    <NoneButton className={scss.cell_button} variant="contained" {...props}>
      <Text className='b2strong-darkGreen'>{props.children}</Text>
    </NoneButton>
  )
}

//---------------------------------------------------------------------------
const PeptideDFBPIDMultiResult = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  let nameDFBPID = queryParameters.get(paths.params.id)
  // let jsonClassify = queryParameters.get(paths.params.class)
  let jsonClassify = 'multifunctionalpeptides'
  const input = spp.peptide.search.id_multi.input(jsonClassify, nameDFBPID) //21
  // const inputPrecursor = jsonsPrecursor.input(jsonClassify, nameDFBPID) //77

  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Peptide', '', bread3rd, nameDFBPID),
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.peptide.id_search(input) //21
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)

        let mcv = output[jsons.output[5]] //"Multi_column_value", //0
        // console.log('Multi_column_value', mcv)
        let bit = output[jsons.output[15]] //3
        // console.log('bitterness', bit)
        let cross = output[jsons.output[19]] //"cross_references", //5
        // console.log('cross_references', cross)
        let obj = {}
        obj.Multi_column_value = mcv
        obj.bitterness = bit
        obj.cross_references = cross
        setOutputObj(obj)
        // console.log(obj)

        let card = []
        for (const i of (Array(4).keys())) {
          card.push(cardData(jsons.output_text[i], output[jsons.output[i]],))
        }
        setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)

      let obj = {}
      obj.Multi_column_value = {}
      obj.bitterness = {}
      obj.cross_references = {}
      setOutputObj(obj)
    })
  }
  
  //---------------------------------------------------------------------------
  const [outputPrecursorObj, setOutputPrecursorObj] = useState({}) //object
  const [statePrecursor, setStatePrecursor] = useState(axiosState.init())
  const apiPrecursorResult = () => {
    const config = apiConfig.peptide.id_precursor_result(input) //77
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePrecursor(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStatePrecursor(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        // setOutput(output)
        // console.log('output', output)
        
        let obj = {}
        let pps = output[jsonsPrecursor.output[0]] //"precursor_protein_search",
        // console.log('precursor_protein_search', pps)
        obj.precursor_protein_search = pps
        setOutputPrecursorObj(obj)
        // console.log(obj)
    }
    }).catch(err => {
      setStatePrecursor(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      let obj = {}
      obj.precursor_protein_search = {}
      setOutputPrecursorObj(obj)
    })
  }
  
  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
    if (axiosState.keepRest(statePrecursor))
      apiPrecursorResult()
  }, [outputPrecursorObj])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    const isCross = (tab.json === tabs._5database.json)
    let indexRef = 0 //bug D number always being from 1
    const title = tab.title
    const is3DStructure = (title === '3D Structure')
    const peptideSeq = output["peptide_sequence"]
    return (
      <>
        <Text className={'h3-darkGreen'}>{title}
          {is3DStructure
          ? <>
              <IconButton onClick={handleClickOpen}>
                <AddIcon style={{color: v.green4}} />
              </IconButton>
              <DialogMolstar seq={peptideSeq} />
            </>
          : null}
          </Text>
        
        {tab.head.map((item, index) => {
          // console.log(index)
          const json = tab.json[index]
          let isHide = false
          let isAddButton = false
          let link = ""
          const isMultiColumn = (item === 'Multi Column Value')
          if (isCross) {
            const label = jsons.cross_references[index]
            const val = outputObj.cross_references[label]
            // console.log(label, val)
            if (val === null)
              isHide = true

            link = getLinkFromArray(jsons.cross_references_link ,item)
          }
          if (item === 'Peptide Calculator'
            || item === "Precursor Protein(s) Search") {
            isAddButton = true
          }
          if( item === 'Molstar' ) {
            let seq = output["peptide_sequence"]
            // console.log('seq', seq);
            
            return (
              <div key={`div-${item}`} className={`${scss.cell_text} ${scss.right_cell_line}`}>
                <iframe
                  src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}`}
                  width="100%"
                  height="540px" //40px + height(molstart index.html #app)
                  title="MolstarViewer"
                  style={{ border: 'none' }}
                />
              </div>)
          }
            
          return (isHide
            ? null
            : !isMultiColumn
              ? <div key={index} className={scss[getScssNameCell(json, tab, index)]}>
                <div key={`div-${item}`} className={scss.cell_label}>
                  <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                    {link !== ""
                      ? <A href={link} className={getClassNameTitle(item)} >
                          <TagText html={item} />
                        </A>
                      : <TagText html={item} />}
                  </Text>
                  {isAddButton ? <CellButton name={item} /> : null}
                </div>
                <div key={`div-${index}`} className={scss.cell_value}>
                  <Text key={`text-${index}`} className={getClassName(json, output)}>
                    {statePrecursor.isLoading && json === "precursor_protein_search"
                    ? <LoadingAnime />
                    : getValue(output, outputObj, tab.json, index, indexRef)}                    
                  </Text>
                </div>
              </div>
              : <MultiTableFromObject
                tableHeadCell={jsons.DFBPID_text}
                tableBodyCell={jsons.DFBPID}
                outputObj={outputObj}></MultiTableFromObject>
          )
        })}
      </>
    )
  }

  const CellButton = (props) => {
    const { name, children } = props
    let text = ''
    if (name === 'Peptide Calculator')
      text = tabs._1physical.button_peptide //'Link-research'
    else if (name === "Precursor Protein(s) Search")
      text = tabs._2peptide.button_view //'View HotSpot'
    else if (name === 'Link-research')
      text = tabs._2peptide.button_inductive //'Inductive Analysis' //unused
    return (
      <div>
        {children}
        <MuiButton onClick={() => handelClickCellButton(text)}>{text}</MuiButton>
      </div>
    )
  }
  const handelClickCellButton = (text) => {
    // console.log('text', text);
    let keyword = output["peptide_sequence"] //'Single-letter Amino Acid'
    if (text === tabs._1physical.button_peptide) { //'Link-research'
      navigate(paths.spp.tool.peptide_calculator_result(keyword))
    } else if (text === tabs._2peptide.button_view) { //'View HotSpot'
      navigate(paths.spp.tool.hotspot_result_chart(keyword, {all: 1, id: nameDFBPID}))
    }
  };
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log('index', index)
    let idx = index
    if (json === tabs._5database.json)
      idx = 0
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)

      if (Object.isObject(value)) {
        // console.log('name', name, 'value', value)
        let string = ''
        switch (name) { //spp.peptide.search.id_multi.output
          case "cross_references":
            const label = jsons.cross_references[index]
            const val = value[label]
            switch (index) {
              case 0: //BIOPEP-UWM
                indexRef += 1
                let txt = ''
                let ary = val.split(',')
                // console.log('val=', val, 'ary=', ary)
                if (ary.length > 1) {
                  for (let i of Array(ary.length).keys()) {
                    txt += links.outside.htmlBIOPEP_UWM(ary[i])
                    if (i !== ary.length - 1)
                      txt += ', '
                  }
                } else if( val !== '-' )
                  txt = links.outside.htmlBIOPEP_UWM(val)
                else
                  txt = val
                string = tabs._5database.text_d(indexRef) + txt
                break
              // case 1: //APD
              // case 2: //BioPepDB
              // case 3: //MBPDB
              default:
                indexRef += 1
                string = tabs._5database.text_d(indexRef) + val
            }
            // console.log('indexRef', indexRef)
            valueReturn = string
            break
          default:
            valueReturn = name
        }
      }
      
      if(value === null) {
        // console.log('name=', name, 'value=null');
        switch (name) { //spp.peptide.search.id_multi.output
          case "precursor_protein_search": //object content move to api 77
            let string = '', line, label, val
            let fragment = output["peptide_sequence"]
            // Source.1: DFBPPR0921 ---- Plant proteins ---- Very-long-chain aldehyde decarbonylase GL1-5
            let ary_label = jsonsPrecursor.precursor_protein_search[0]
            let ary = outputPrecursorObj.precursor_protein_search[ary_label]
            // console.log('precursor_protein_search', ary);
            
            if( ary !== undefined && Array.isArray(ary) && ary.length > 0 ) {
              // console.log('precursor_protein_search size', ary.length);
              return (
                <div style={{display: 'block',
                  maxHeight: '450px',
                  overflowY: 'auto',
                  paddingRight: '12px',
                  minHeight: '36px'}}
                >
                  {ary.map((item, i) => {
                    line = ''
                    for (let j = 0; j < jsonsPrecursor.precursor_protein_search.length; j++) {
                      label = jsonsPrecursor.precursor_protein_search[j]
                      val = outputPrecursorObj.precursor_protein_search[label]
                      // console.log(val)
                      if (j === 0)
                        line += tabs._2peptide.precursor_first(i) + item
                      else
                        line += ' - ' + val[i]
                    }
                    string = line + '\n'
                    // console.log(string);
                    return (
                      <RouterLink newTab
                        to={paths.spp.tool.hotspot_result_precursor(nameDFBPID, item, fragment)} >
                        {string}</RouterLink>)
                })}
              </div>)
            } else {
              valueReturn = tabs._2peptide.no_precursor
            }
            break
          default:
            // console.log('value', value);
            valueReturn = ''
        }
      } else {
        if(!Array.isArray(value) && !Object.isObject(value)) {
          switch (name) { //spp.peptide.search.id_multi.output
            case "bitterness":
              return (<>
                  <Text>{`${value} `}</Text>
                  <sup>
                    <RouterLink newTab to={paths.spp.tool.bpp_tool_result()}
                      onClick={() => handleClickBitness(output)}>
                      {tabs._3taste.bitness}</RouterLink>
                  </sup>
                </>)
            case "theoretical_mass":
              valueReturn = (tabs._1physical.unit_c(value))
              break
            case "net_charge":
            case "isoelectric_point":
              let valueFix = Number(value).toFixed(2)
              valueReturn = (tabs._1physical.unit_c(valueFix))
              break
            default:
              // console.log('value', value);
              valueReturn = value
          }
        }
      }
    } else {
      // console.log('name', name);
      switch (name) { //tabs.xxx.json
        case tabs._4stability.json[0]: //"Databases: Predict tools",
          let head = ''
          let string = []
          for (let i of Array(tabs._4stability.json.length).keys()) {
            const txt = tabs._4stability.json[i]
            const val = tabs._4stability.body[i]
            const link = getLinkFromArray(tabs._4stability.links, val)
            const css = getClassNameTitle(val)
            if (link !== "")
              string[i] = `${txt}: <a href=${link} className={${css}} target="_blank" rel="noopener noreferrer"><u>${val}</u></a>\n`
            else {
              if( i === 0 ) {
                head = `${txt}: `
                string[i] = `${val}\n`
              } else {
                string[i] = `${txt}: ${val}\n`
              }}
          }
          return (
            <>
              {head}
              <RouterLink newTab to={paths.spp.tool.ehp_tool_search()}
                onClick={() => handleClickEHPtool(output)}>
                {string[0]}
              </RouterLink>
              <TagText html={`${string[1]}`} />
            </>)
        default:
          valueReturn = name
      }
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell from object
  const MultiTableFromObject = (props) => {
    const { tableHeadCell, tableBodyCell, outputObj } = props
    const objects = outputObj.Multi_column_value
    // console.log('objects', objects);
    return (
      Object.entries(objects).map((object, index) => {
        const key = object[0]
        const items = object[1]
        // console.log('key=', key, 'items=', items);
        return (
          <>
            <div key={index} className={scss.right_cell_last}>
              <div key={`div-${index}`} className={scss.cell_label}>
                <Text key={`text-${index}`} className={getClassNameTitle(key)}>{key}</Text>
              </div>
              <div key={`div-${index}`} className={scss.cell_value}>
                <Text key={`text-${index}`} className={getClassName(key)}>{Object.entries(items).length}</Text>
              </div>
            </div>
            <div key={index} className={scss.right_cell_last}>
              <TableFromObject key={key}
                tableHeadCell={tableHeadCell}
                tableBodyCell={tableBodyCell}
                items={items}></TableFromObject>
            </div>
          </>
        )
      }))
  }
  const TableFromObject = (props) => {
    const { tableHeadCell, tableBodyCell, items } = props
    // console.log('items', items);
    return (
      <TableContainer variant="outlined" className={"table_container"}>
        <Table size="small">
          <TableHeadRow className="bgc-dark-green">
            {tableHeadCell.map((cell, index) => (
              <MuiTableCell key={index}
                className="bgc-dark-green color-white"
                sx={{
                  borderTopLeftRadius: (index === 0) ? '8px' : '0px',
                  borderTopRightRadius: (index === tableHeadCell.length-1) ? '8px' : '0px',
                }}
              >{cell}</MuiTableCell>
            ))}
          </TableHeadRow>
          <MuiTableBody>
            {tableBody(tableBodyCell, items)}
          </MuiTableBody>
        </Table>
      </TableContainer>
    )
  }
  const tableBody = (tableBodyCell, items) => {
    // console.log(items)
    return (
      Object.entries(items).map((item) => {
        const key = item[0]
        const value = item[1]
        // console.log('key=', key, 'items=', items);
        return (
          <TableRow2Body key={key}>
            <TableBodyCell tableBodyCell={tableBodyCell} id={key}>{value}</TableBodyCell>
          </TableRow2Body>
        )
      }))
  }
  const TableBodyCell = (props) => {
    const {id, ...other} = props
    // console.log('props', props);
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    return (
      props.tableBodyCell.map((cell, index) => {
        const text = props.children[cell]
        return (
          <MuiTableCell key={index}>
            {index === 0
              ? <A href={paths.spp.peptide.id_result(id, text)} className={'link'}>{id}</A>
              : <TagText html={text} />}
          </MuiTableCell>
        )
      }))
  }
  
  //------------------------------------------------------------------------------
  const [openMolstar, setOpenMolstar] = React.useState(false);

  const handleClickOpen = () => {
    setOpenMolstar(true);
  };

  const handleClose = () => {
    setOpenMolstar(false);
  };
  
  const DialogMolstar = (props) => {
    const { seq } = props
    return (
      <Dialog
        fullWidth={true}
        maxWidth={false}
        // fullScreen={true}
        open={openMolstar}
        onClose={handleClose}
      >
        <DialogContent style={{ height: '90vh' }}>
          <iframe
            src={`${process.env.PUBLIC_URL}${paths.molstar}/index.html?seq=${seq}`}
            width="100%"
            height="100%" //40px + height(molstart index.html #app)
            title="MolstarViewer"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    )
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  /********************************************
   * Initialize activeTab & Listen scroll - Start
   ********************************************/
  /* useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); */
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
 
  const ContentObjList = [
    <TabCell tab={tabs._0multifunctional} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1physical} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._2peptide} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._3taste} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._31structure} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._4stability} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._5database} output={output} outputObj={outputObj}></TabCell>
  ]

  return (
    <LayoutPage2 bread={words.bread} card={card} output={output}>
      <div className={scss.layout}>
        <div className={scss.frame_left}>
          <StyledTabs value={tabValue}
            onChange={(e, value) => handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} label={item} />
            ))}
          </StyledTabs>
        </div>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scss.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2>
  )
}

export default PeptideDFBPIDMultiResult;