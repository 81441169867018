import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import { useRef } from "react";

import Footer from "layouts/Footer";
import Navbar from "layouts/Navbar";
import cookie from "utils/cookie";
import Router from "./routes";
import ScrollToTop from "components/ScrollToTop";
import SnackBar, { snackInfo } from "components/SnackBar";

//---------------------------------------------------------------------------
/* 
javascript - React - check when tab or browser is closed, but not on refresh - Stack Overflow
https://stackoverflow.com/questions/59840619/react-check-when-tab-or-browser-is-closed-but-not-on-refresh
 */
const App = () => {
  // const windowWidth = useRef(window.innerWidth);
  // const windowHeight = useRef(window.innerHeight);
  // console.log('width', windowWidth.current);
  // console.log('height', windowHeight.current);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      window.localStorage.isMySessionActive = "true";
    });
    window.onunload = function (e) {
      const newTabCount = localStorage.getItem("tabsOpen");
      if (newTabCount !== null) {
        localStorage.setItem("tabsOpen", newTabCount - 1);
      }
    };

    // define increment counter part
    const tabsOpen = localStorage.getItem("tabsOpen");
    if (tabsOpen == null) {
      localStorage.setItem("tabsOpen", 1);
    } else {
      localStorage.setItem("tabsOpen", parseInt(tabsOpen) + parseInt(1));
    }

    // define decrement counter part
    window.onunload = function (e) {
      const newTabCount = localStorage.getItem("tabsOpen");
      if (newTabCount !== null) {
        localStorage.setItem("tabsOpen", newTabCount - 1);
      }
    };
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      window.localStorage.isMySessionActive = "false";
    } else {
      const newTabCount2 = localStorage.getItem("tabsOpen");
      let value = localStorage.getItem("isMySessionActive");
      if (value === "true") {
        if (newTabCount2 - 1 <= 0) {
          localStorage.clear();
          cookie.removeCookieUser();
          window.localStorage.isMySessionActive = "false";
        } else {
          window.localStorage.isMySessionActive = "false";
        }
      }
    }
  }, []);
  
  const [info, setInfo] = useState(snackInfo.init())
  // const pathname = window.location.pathname
  // console.log(pathname);
  
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar setInfo={setInfo} />
        <ScrollToTop />
        <Router setInfo={setInfo} />
        <Footer />
        
        <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />
      </div>
    </BrowserRouter>
  );
};

export default App;
